.form-container {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .form-container input,
  .form-container button,
  .form-container select {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  .form-container .phoneInput{
    width: 100%;
  }
  .form-container button {
    background-color: #4285f4;
    color: #fff;
    cursor: pointer;
  }
  .form-container button:disabled,
  .form-container button[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .phone-group {
    display: 'flex'
  }
  .notification{
    color: #ce0101;
  }